import React from "react";
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';
import { ReactComponent as Etoro } from './svg/etoro.svg';

const popover = (
  <Popover id="popover-basic" className="shadow pt-2 pl-2">
    <Popover.Header className="bg-transparent border-0 mb-0 pb-0 small text-white ">
      <span className="mr-2 mt-1 mb-0 small text-muted">Patrocinado</span>
      {// eslint-disable-next-line
      }<img src="https://ad.doubleclick.net/ddm/ad/N1224350.4398694LIVECOINS/B27370993.330488136;sz=1x1;ord=[timestamp];dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755}?" border="0" width="1" height="1" alt="Advertisement" />
    </Popover.Header>
    <Popover.Body>
      <a className="text-decoration-none stretched-link text-black text-center" href='https://ad.doubleclick.net/ddm/clk/522678460;330488136;l' rel="noopener noreferrer" target="_blank" >
        <Etoro className="mx-4 mb-1 pb-2" />
        Compre e invista em criptomoedas com segurança na eToro.
      </a>
      <div className="pt-3 text-center">
        <Button variant="greenlime" className="px-5 mb-2 link-light" style={{ zIndex: "2", position: "relative" }} href="https://ad.doubleclick.net/ddm/clk/522678460;330488136;l" rel="noopener noreferrer" target="_blank">Compre agora!</Button>
      </div>
    </Popover.Body>
  </Popover>
)

const MainAd = () => (
  <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={popover}><Button className="mx-2 sponsored link-light" variant="secondary" size="sm" style={{ fontWeight: "600", fontSize: "90%" }}>Comprar</Button></OverlayTrigger>
)

export default MainAd