import React from "react";
import { Container, Row, Col, Button, Spinner, Placeholder } from 'react-bootstrap';
import CoinsTable from './components/MainTable'
import ScrollToTop from "./components/ScrollToTop";
import ReactGA from 'react-ga';

class SymbolTable extends React.Component {
  constructor(props) {
    super(props);
    this.LoadMore = this.LoadMore.bind(this);
    this.state = {
      error: null,
      isLoading: false,
      isLoaded: false,
      coinsfull: [],
      coins: [],
      last: []
    };
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
    this.getCoins();
    this.getLast();
    this.timer = setInterval(() => (this.getLast()), 15000);
    document.title = "MercadoCripto · Preço, volume e gráficos do mercado de criptomoedas brasileiro"
  }

  getLast() {
    fetch(process.env.REACT_APP_API_URL + "/v1/ticker/last")
      .then(res => res.json())
      .then(
        (result) => {
          let tempState = [...this.state.coins];
          this.setState({
            coins: tempState.map(x => Object.assign(x, result.find(y => y.Symbol === x.Symbol))),
            last: result
          })
          Array.from(document.getElementsByClassName('toupdate')).forEach((el) => el.classList.add('updated'));
        },
        // handle errors
        (error) => {
          this.setState({
            error
          });
        }
      );
  }

  getCoins() {
    Promise.all([fetch(process.env.REACT_APP_API_URL + "/v1/ticker/market-data"), fetch(process.env.REACT_APP_API_URL + "/v1/ticker/chart")])
      .then(([res1, res2]) => {
        return Promise.all([res1.json(), res2.json()])
      })
      .then(([res1, res2]) => {
        this.setState({
          coinsfull: res1.sort((a, b) => parseFloat(b.QuoteVolume) - parseFloat(a.QuoteVolume)),
          coins: res1.sort((a, b) => parseFloat(b.QuoteVolume) - parseFloat(a.QuoteVolume)).slice(0, 10),
          chart: res2
        })
      },
        // handle errors
        (error) => {
          this.setState({
            error
          });
        }
      );
  }

  LoadMore = (newState) => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ coins: newState, isLoaded: true, isLoading: false });
    }, 1);
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
    clearInterval(this.timer);
  }

  render() {
    const { error, coins, last, chart, coinsfull, isLoaded, isLoading } = this.state;
    if (error) {
      return <Container className="py-3"><h5 className="mt-5 pt-5 text-center text-muted">Erro no servidor. Volte mais tarde.</h5> {console.log(error.message)}</Container>;
    } else {
      return (
        <>
          <div className="bg-light border-bottom pb-3">
            <Container className="py-4">
              <h1 className="h5">Mercado de Criptomoedas Brasileiro</h1>
              <p className="text-muted maintext d-none d-md-block">Portal agregador de informações de corretoras que operam com Real Brasileiro (BRL)</p>
              <Row className="pt-3 text-center">
                <Col md={4}>
                  <h2 className="h6 text-muted ">Volume movimentado (24h)</h2>
                  <span className="h5">{coinsfull.reduce((a, e) => a + e.QuoteVolume, 0) === 0 ?  <Placeholder as="p" animation="glow"><Placeholder className="rounded" xs={7} /></Placeholder> : coinsfull.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                </Col>
                <Col md={4} className="text-center pt-1">
                  <h3 className="h6 text-muted">Criptomoedas</h3>
                  <span className="h5">{coinsfull.length === 0 ? <Placeholder as="p" animation="glow"><Placeholder className="rounded" xs={2} /></Placeholder>  : coinsfull.length}</span>
                </Col>
              </Row>
            </Container>
          </div>
          <Container className="text-center mb-3">
            <CoinsTable data={coins} last={last} chart={chart} />
            {!isLoading && !isLoaded ? <Button variant="outline-primary" className="btn-border-2 mb-2" onClick={() => this.LoadMore(coinsfull)}>Carregar mais</Button>
              : (!isLoading && isLoaded) ? '' : <Button variant="primary"  className="mb-2" style={{ border: "2px solid" }} id="loadingmore" disabled><Spinner as="span" animation="border" size="sm" role="status" /> Carregando... </Button>}
            <div className="float-end">
              <div className="back-to-top-wrapper"><ScrollToTop /></div>
            </div>
          </Container>
        </>
      );
    }
  }
}
export default SymbolTable;