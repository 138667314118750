import React from "react";
import { ListGroup} from 'react-bootstrap';
import { NavLink } from "react-router-dom";

const Footer = () => (
    <footer className="bg-light text-md-start text-center my-0 border-top">
        <ListGroup id="footer" horizontal="sm" className="py-3 justify-content-evenly">
                <ListGroup.Item className="border-0">© {(new Date()).getFullYear()} mercadocripto</ListGroup.Item>
                <ListGroup.Item className="footerlink nav-link border-0" activeClassName="active" as={NavLink} to="/contato">Contato</ListGroup.Item>
                <ListGroup.Item className="footerlink nav-link border-0" activeClassName="active" as={NavLink} to="/metodologia">Metodologia</ListGroup.Item>
                <ListGroup.Item className="footerlink nav-link border-0 d-sm-none d-md-block"></ListGroup.Item>
        </ListGroup>
    </footer>
)
export default Footer