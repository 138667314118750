import React from "react";
import SymbolMarket from "./SymbolMarket";
import SymbolsTable from './Main';
import Metodologia from './Metodologia';
import Contato from './Contato';
import ExchangeSummary from './ExchangeSummary';
import { Router, Switch, Route } from "react-router-dom";
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import LivecoinsHeader from "./components/Livecoins/LivecoinsHeader";
import Relatorios from './components/Relatorios';
import Exchanges from './Exchanges';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ScrollToTopNavigation from "./components/ScrollToTopNavigation";
import withTracker from './utils/withTracker';
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

function App() {
    return (
        <>
            <Router history={history}>
                <LivecoinsHeader />
                <NavBar />
                <ScrollToTopNavigation />
                <div className="mainpage">
                    <Switch>
                        <Route exact path="/" component={withTracker(SymbolsTable)} />
                        <Route exact path="/metodologia" component={withTracker(Metodologia)} />
                        <Route exact path="/contato" component={withTracker(Contato)} />
                        <Route exact path="/relatorios" component={withTracker(Relatorios)} />
                        <Route exact path="/corretoras" component={withTracker(Exchanges)} />
                        <Route exact path="/corretoras/:exchange" component={withTracker(ExchangeSummary)} />
                        <Route exact path="/:Symbol" component={withTracker(SymbolMarket)} />
                        <Route component={withTracker(NotFound)} />
                    </Switch>
                </div>
                <Footer />
            </Router>

        </>
    );
}

export default App;

