import React from 'react';
import Parser from 'rss-parser';
import { Card, CardGroup } from 'react-bootstrap';


export default class NewsFeed extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            slug: props.slug,
            feed: [],
            error: null
        };
    }

    async componentDidMount() {
        try {
            let parser = new Parser();
            var proxy = process.env.NODE_ENV !== 'production' ? "https://livecoins.com.br/" : '';
            const feed = await parser.parseURL(proxy + "https://livecoins.com.br/" + this.props.slug.toLowerCase().split(' ')[0] + '/feed/');
            this.setState({ feed: feed.items });
        } 
        catch (error) { console.log(error)
            this.setState({ error: error });}
    }

    render() {
        const epochs = [
            ['ano', 31536000],
            ['', 2592000],
            ['dia', 86400],
            ['hora', 3600],
            ['minuto', 60],
            ['segundo', 1]
        ];
        const getDuration = (timeAgoInSeconds) => {
            for (let [name, seconds] of epochs) {
                const interval = Math.floor(timeAgoInSeconds / seconds);
                if (interval >= 1) {
                    return {
                        interval: interval,
                        epoch: name
                    };
                }
            }
        };
        const timeAgo = (date) => {
            const timeAgoInSeconds = Math.floor((new Date() - new Date(date)) / 1000);
            const { interval, epoch } = getDuration(timeAgoInSeconds);
            const suffix = (interval === 1 && epoch === '') ? 'mês' :
                (interval !== 1 && epoch === '') ? 'meses' :
                    (interval === 1 && epoch !== '') ? '' : 's';
            return `${interval} ${epoch}${suffix} atrás`;
        };
        const { feed,error } = this.state;
        if (error) {
            return <></>
        } else {
            return (
                <>
                    <h3>Notícias</h3>
                    <CardGroup>
                        {feed.slice(0, 3).map(i => {
                            return (
                                <Card id="feed" key={i.title}>
                                    <Card.Img variant="top" src={"https://livecoins.com.br/" + i.content.match(/wp.*?.(jpg|png|jpeg|gif)/)[0]} alt={i.title}/>
                                    <Card.Body>
                                        <Card.Title>{i.title}</Card.Title>
                                        <Card.Text>
                                        <a href={i.link} rel="noopener noreferrer" target="_blank" className="stretched-link" style={{ cursor: "pointer" }}>{i.contentSnippet.slice(0, 200)}...
                                             </a>
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <small className="text-muted">{getDuration === undefined ? '1 segundo atrás' : timeAgo(i.isoDate)}</small>
                                    </Card.Footer>
                                </Card>
                            )
                        })}
                    </CardGroup>
                </>
            );
        }
    }
}