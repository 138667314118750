import React from "react";
import { InputGroup, FormControl, Form, Col, Row } from 'react-bootstrap';
import { ReactComponent as ExchangeIcon } from './svg/exchange.svg';

function Converter(props) {
  const [value, updateValue] = React.useState({ crypto: 1, brl: props.symbol.Last.toFixed(2) })

  const updateBRL = ev => updateValue({
    brl: ev.target.value,
    crypto: (+ev.target.value / props.symbol.Last).toFixed(8)
  })

  const updateBTC = ev => updateValue({
    brl: (+ev.target.value * props.symbol.Last).toFixed(2),
    crypto: ev.target.value
  })

  return (
    <Form>
      <Row  className="align-items-center">
        <Col xs md="3">
        <InputGroup className="mb-2 mr-sm-2">
        <InputGroup.Text id="reais_addon">BRL</InputGroup.Text>
            <FormControl id="reais_addon" type="number" value={value.brl} onChange={updateBRL}
              aria-label="R$" aria-describedby="reais_addon" />
          </InputGroup>
        </Col>
        <Col xs="auto" className="d-none d-sm-block pb-2 px-0">
            <ExchangeIcon />
        </Col>
        <Col xs md="3">
        <InputGroup className="mb-2 mr-sm-2">
        <InputGroup.Text id="btc_addon">{props.symbol.Symbol.slice(0,-3)}</InputGroup.Text>
            <FormControl id="btc_addon" type="number" value={value.crypto} onChange={updateBTC}
              aria-label="{props.symbol.Symbol.slice(0,3)}" aria-describedby="btc_addon" />
          </InputGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default Converter