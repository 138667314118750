import React, { useState, useEffect } from "react";
import MonthReport from "../charts/MonthReport"
import { Container, Row, Form, Col, Spinner } from 'react-bootstrap';


const Relatorios = (props) => {
  const now = new Date();

  const [selectedCrypto, setSelectedCrypto] = useState('?symbol=BTCBRL');
  const [selectedMonth, setSelectedMonth] = useState(now.getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(now.getFullYear());
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [coinList, setcoinList] = useState([]);
  const [allYears, setallYears] = useState([]);


  function handleSelectChange(event) {
    setSelectedCrypto(event.target.value);
  }
  function handleSelectChangeMonth(event) {
    setSelectedMonth(event.target.value);
  }
  function handleSelectChangeYear(event) {
    setSelectedYear(event.target.value);
  }
  useEffect(() => {
    document.title = "Relatórios mensais do volume negociado de criptomoedas no Brasil · MercadoCriptoBR";
  }, []);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/v1/symbols")
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setcoinList(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  useEffect(() => {
    let startYear = 2021;
    const currentYear = new Date().getFullYear();
    let years = [];
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    setallYears(years);
  }, [])

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <Container className="text-center p-5"><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
  } else {
    return (
      <>
        <div className="bg-light border-bottom py-4">
          <Container>
            <h1 className="h3 font-weight-bolder">Dados Mensais</h1>
            <p>Abaixo você encontra dados consolidados do volume negociado no mês de cada criptomoeda.<br /> Selecione a criptomoeda e o mês do seu interesse para gerar o gráfico e relatório.</p>
            <hr />
              <Row className="align-items-center">
                <Col sm={3} className="my-1">
                  <Form.Group controlId="cryptocurrencyselect">
                    <Form.Label>Criptomoeda</Form.Label>
                    <Form.Select value={selectedCrypto} onChange={handleSelectChange}>
                      <option value="all?symbol=BRLBRL" key="todas">Todas</option>
                      {coinList.map((data, index) => {
                        if (data) {
                          return (
                            <option value={"?symbol=" + data.Symbol} key={data.Symbol}>{data.SymbolName + " - " + data.Symbol.slice(0, -3)}</option>
                          )
                        }
                        return null
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={3} className="my-1">
                  <Form.Group controlId="monthselect">
                    <Form.Label>Mês</Form.Label>
                    <Form.Select value={selectedMonth} onChange={handleSelectChangeMonth}>
                      <option value="1" key="1">Janeiro</option>
                      <option value="2" key="2">Fevereiro</option>
                      <option value="3" key="3">Março</option>
                      <option value="4" key="4">Abril</option>
                      <option value="5" key="5">Maio</option>
                      <option value="6" key="6">Junho</option>
                      <option value="7" key="7">Julho</option>
                      <option value="8" key="8">Agosto</option>
                      <option value="9" key="9">Setembro</option>
                      <option value="10" key="10">Outubro</option>
                      <option value="11" key="11">Novembro</option>
                      <option value="12" key="12">Dezembro</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={3} className="my-1">
                  <Form.Group controlId="yearselect">
                    <Form.Label>Ano</Form.Label>
                    <Form.Select value={selectedYear} onChange={handleSelectChangeYear}>
                      {allYears.map((data, index) => {
                        return (
                          <option value={data} key={data}>{data}</option>
                        )
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
          </Container>
        </div>

        <Container>
          {selectedCrypto.length !== 0 ? <MonthReport symbol={selectedCrypto} month={selectedMonth} year={selectedYear} /> : <br />}

        </Container>
      </>
    )
  }
}

export default Relatorios