import React, { useState, useEffect } from "react";
import { Container, Spinner, Row, Col } from 'react-bootstrap';
import ExchangesTable from './components/ExchangesTable'
import { ReactComponent as Caret } from './components/caret-up-fill.svg';
import ScrollToTop from "./components/ScrollToTop";
import { doFetchJson } from "./utils/api";

const Exchanges = (props) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false)
  const [exchanges, setExchanges] = useState([])

  useEffect(() => {
    const getSummary = () => {
      fetch(process.env.REACT_APP_API_URL + "/v1/exchanges/all")
        .then(res => doFetchJson(res))
        .then(res => {
          setExchanges(res)
          setIsLoaded(true)
        })
        .catch((error) => {
          setError(error)
          setIsLoaded(true)
        })
      Array.from(document.getElementsByClassName('toupdate')).forEach((el) => el.classList.add('updated'));
    };
    let timer = setInterval(() => (getSummary()), 15000);
    document.title = "Corretoras de criptomoedas com suporte para real brasileiro (BRL) organizadas por volume · MercadoCripto"
    getSummary();
    return () => { clearInterval(timer); }
  }, []);


  let volumevar = ((exchanges.reduce((a, e) => a + e.QuoteVolume, 0)) - (exchanges.reduce((a, e) => a + e.YesterdayVolume, 0))) / (exchanges.reduce((a, e) => a + e.YesterdayVolume, 0));
  if (error) {
    return <Container className="py-3"><h5 className="mt-5 pt-5 text-center text-muted">{error.message}</h5></Container>;
  } else if (!isLoaded) {
    return <Container className="text-center p-5"><br /><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
  } else {
    return (
      <>
        <div className="bg-light border-bottom pb-3">
          <Container className="py-4">
            <h1 className="h3 text-center text-md-left">Corretoras Nacionais</h1>
            <Row className="pt-3 text-center">
              <Col md={4}>
                <h2 className="h6 text-muted">Volume movimentado em BRL (24h)</h2>
                <span className="h5">{exchanges.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                {volumevar >= 0
                  ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{volumevar.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })} </span>
                  : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{volumevar.toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)} </span>}
              </Col>
              <Col md={4} className="text-center">
                <h3 className="h6 text-muted">Corretoras que negociam em BRL</h3>
                <span className="h5">{exchanges.length}</span>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="overflow-auto pt-4 ">
          <ExchangesTable data={exchanges} />
          <div className="float-end">
            <div className="back-to-top-wrapper"><ScrollToTop /></div>
          </div>
        </Container>
      </>
    );
  }
}

export default Exchanges;