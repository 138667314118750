import React from "react";
import { Nav, Row, Col, Container, Spinner, Tab, Badge, Breadcrumb, Table, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import SymbolMarketTable from './components/SymbolMarketTable';
import ComboChart from "./charts/ComboChart";
import Converter from "./components/Converter";
import coinlogo from "./components/logos/coinlogo.svg";
import NotFound from "./components/NotFound";
import NewsFeed from "./components/NewsFeed";
import { ReactComponent as Caret } from './components/caret-up-fill.svg';


class SymbolMarket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      frequency: 300,
      interval: 1440,
      active: '1D'
    };
  }

  componentDidMount() {
    this.timer = setInterval(() => this.getAPI(), 15000);
    this.getAPI()
  };

  getAPI() {
    var symbol = this.props.match.params.Symbol.toUpperCase();
    var exchangeapi = (process.env.REACT_APP_API_URL + "/v1/ticker/last/exchanges/" + symbol);
    var lastapi = (process.env.REACT_APP_API_URL + "/v1/ticker/last?symbols=BTCBRL,USDTBRL," + symbol);

    Promise.all([fetch(exchangeapi), fetch(lastapi)])
      .then(([res1, res2]) => {
        return Promise.all([res1.json(), res2.json()])
      })
      .then(([res1, res2]) => {
        this.setState({
          isLoaded: true,
          exchanges: res1.sort((a, b) => parseFloat(b.Volume) - parseFloat(a.Volume)),
          coins: res2.filter(function (entry) {
            return entry.Symbol === symbol
          }),
          btc: res2.filter(function (entry) {
            return entry.Symbol === 'BTCBRL'
          }),
          usdt: res2.filter(function (entry) {
            return entry.Symbol === 'USDTBRL'
          }),
        });
      },
        // handle errors here
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      );
    Array.from(document.getElementsByClassName('toupdate')).forEach((el) => el.classList.add('updated'));
  }

  handleClick = (f, i) => (e) => {
    const clicked = e.target.id
    this.setState({ frequency: f, interval: i });
    if (this.state.active === clicked) {
      this.setState({ active: '' });
    } else {
      this.setState({ active: clicked })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.Symbol.toUpperCase() !== prevProps.match.params.Symbol.toUpperCase()) {
      this.setState({
        isLoaded: false,
      })
      this.getAPI();
    }
    else { }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
    clearInterval(this.timer);
  }

  render() {
    const { error, isLoaded, exchanges, coins, btc, usdt } = this.state;
    if (error) {
      return <Container className="py-3"><h5 className="mt-5 pt-5 text-center text-muted">Erro no servidor. Volte mais tarde.</h5> {console.log(error.message)}</Container>
    } else if (!isLoaded) {
      return <Container className="text-center p-5"><Spinner variant="primary" className="m-5" animation="border" role="status"><span className="visually-hidden">Carregando...</span></Spinner></Container>;
    } else if (exchanges[0] === undefined) {
      return <NotFound />
    } else {
      const days_into_ytd = Math.floor((Date.now() - Date.parse(new Date().getFullYear(), 0, 0)) / 60000) // to get days into year, divide by 86400000 instead of 60000
      let btcvar = ((coins[0].Last) / btc[0].Last);
      let btcvar24 = ((coins[0]['24h']) / btc[0]['24h']);
      let usdtvar = ((coins[0].Last) / usdt[0].Last);
      let usdtvar24 = ((coins[0]['24h']) / usdt[0]['24h']);
      document.title = (exchanges[0].Symbol + " " + ((coins[0].Last > 0.1) ? coins[0].Last.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ("R$ " + coins[0].Last.toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 5 }))) + " · " + exchanges[0].SymbolName + " · Preço em real brasileiro, volume e gráficos · MercadoCripto")
      return (
        <>
          <div className="bg-light">
            <Container>
              <Breadcrumb className="pt-2">
                <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }} >
                  Criptomoedas
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{exchanges[0].SymbolName}</Breadcrumb.Item>
              </Breadcrumb>
              <div className="mx-auto text-center py-2 rounded mb-2 d-none d-sm-block" alt="Ad" style={{ width: "72vw", height: "9vw", maxWidth: "728px", maxHeight: "90px", background: "linear-gradient(10deg,rgb(54, 96, 153) 0%,rgb(93 166 227) 100%)" }}><Link to="contato" className="text-decoration-none text-white h2">Anuncie aqui</Link></div>
              <div className="mx-auto text-center py-2 rounded mb-3 d-sm-none d-block" alt="Ad" style={{ width: "100%", height: "40vw", background: "linear-gradient(10deg,rgb(54, 96, 153) 0%,rgb(93 166 227) 100%)" }}><Link to="contato" className="text-decoration-none text-white h2">Anuncie aqui</Link></div>
              <Row className="d-flex mb-2">
                <Col xs="3" md="auto" className="p-1 pl-3 py-0 symbolmarket-responsive">
                  <svg className="coinlogo mb-1" role="img" aria-labelledby="title">
                    <title id="title">{exchanges[0].SymbolName}</title>
                    <use href={coinlogo + "#" + (exchanges[0].Symbol.slice(0, -3)).toLowerCase() + "-logo"}></use>
                  </svg>
                </Col>
                <Col>
                  <h1 className="h2 text-nowrap">{exchanges[0].SymbolName} <span className="text-muted h5"> {exchanges[0].Symbol.slice(0, -3)}</span></h1>
                  <h2 className="h3 strong">{(coins[0].Last > 0.1) ? coins[0].Last.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ("R$ " + coins[0].Last.toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 5 }))}
                  </h2>
                  {(exchanges[0].SymbolName === "Tether" || exchanges[0].SymbolName === "Binance USD" || exchanges[0].SymbolName ===  "USD Coin") ?
                   "" :
                    <h3 className="h6 text-muted strong ">{usdtvar.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} USDT
                      {(usdtvar - usdtvar24) >= 0
                        ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{((usdtvar - usdtvar24) / usdtvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                        : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{((usdtvar - usdtvar24) / usdtvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)}</span>
                      }
                    </h3>
                    }
                  {exchanges[0].SymbolName !== "Bitcoin" ?
                    <h3 className="h6 text-muted strong ">{btcvar.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 8, minimumFractionDigits: 5 })} BTC
                      {(btcvar - btcvar24) >= 0
                        ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{((btcvar - btcvar24) / btcvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                        : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{((btcvar - btcvar24) / btcvar24).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)}</span>
                      }
                    </h3>
                    : ""}
                </Col>
                <Col className="ml-4 pt-3">
                  <Table borderless responsive className="openmarket-table">
                    <tbody>
                      <tr>
                        <td><span className="h6">Abertura</span></td>
                        <td><span className="h6">{coins[0]['24h'].toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span></td>
                      </tr>
                      <tr>
                        <td><span className="h6">24h %</span></td>
                        <td>{(coins[0].Last - coins[0]['24h']) >= 0
                          ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{((coins[0].Last - coins[0]['24h']) / coins[0]['24h']).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                          : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{((coins[0].Last - coins[0]['24h']) / coins[0]['24h']).toLocaleString('pt-BR', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)}</span>}
                        </td>
                      </tr>
                      <tr>
                        <td><span className="h6">Var 24h</span></td>
                        <td>{(coins[0].Last - coins[0]['24h']) >= 0
                          ? <span className="h6 text-success text-nowrap"> <Caret className="icontable" />{(coins[0].Last - coins[0]['24h']).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                          : <span className="h6 text-danger text-nowrap"> <Caret className="icon-rotated" />{(coins[0].Last - coins[0]['24h']).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }).substring(1)}</span>}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col className="ml-4 pt-3">
                  <Table borderless responsive className="openmarket-table">
                    <tbody>
                      <tr><td><span className="h6">Volume 24h</span></td></tr>
                      <tr><td><span className="h6 strong toupdate"> {exchanges.reduce((a, e) => a + e.QuoteVolume, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}<br /></span></td></tr>
                      <tr><td><span className="h6 text-muted toupdate">{exchanges.reduce((a, e) => a + e.Volume, 0).toLocaleString('pt-BR', { style: 'decimal', maximumFractionDigits: 0 })} {exchanges[0].Symbol.slice(0, -3)} </span></td></tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <div className="pt-3">
                <Converter symbol={coins[0]} />
              </div>
            </Container>
          </div>

          <Tab.Container justify defaultActiveKey="Corretoras" id="tab-markets" >
            <div className="bg-light border-bottom  pb-0" id="borderfull">
              <Container className="bg-light d-block bgfull px-0">
                <Nav variant="tabs pl-3">
                  <Nav.Item>
                    <Nav.Link eventKey="Corretoras">Corretoras <Badge pill bg="gray" className="counter" title="Corretoras disponíveis">{exchanges.length}</Badge></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Gráfico">Gráfico</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Container></div>
            <Container>
              <Tab.Content className="mt-4">
                <Tab.Pane eventKey="Corretoras">
                  <Container className="px-0 overflow-auto">
                    <SymbolMarketTable data={exchanges} price={coins[0]} />
                  </Container>
                </Tab.Pane>

                <Tab.Pane eventKey="Gráfico">
                  <Row>
                    <Col>
                      <h4 className="h5" style={{ fontWeight: "600" }}>{exchanges[0].SymbolName} e Real brasileiro <span className="h6 ml-1 text-muted"> {exchanges[0].Symbol}</span></h4>
                    </Col>
                    <Col md="auto">
                      <div className="text-right p-1 bg-light rounded border" id="chart-control">
                        <Button className={`mr-1 ${this.state.active === "1D" ? 'active' : ''}`} variant="light" id="1D" size="sm" onClick={this.handleClick(300, 1440)}>
                          1D
                        </Button>
                        <Button className={`mr-1 ${this.state.active === "7D" ? 'active' : ''}`} variant="light" id="7D" size="sm" onClick={this.handleClick(600, 10080)}>
                          7D
                        </Button>
                        <Button className={`mr-1 ${this.state.active === "1M" ? 'active' : ''}`} variant="light" id="1M" size="sm" onClick={this.handleClick(3600, 43200)}>
                          1M
                        </Button>
                        <Button className={`mr-1 ${this.state.active === "3M" ? 'active' : ''}`} variant="light" id="3M" size="sm" onClick={this.handleClick(7200, 129600)}>
                          3M
                        </Button>
                        <Button className={`mr-1 ${this.state.active === "YTD" ? 'active' : ''}`} variant="light" id="YTD" size="sm" onClick={this.handleClick(28800, days_into_ytd)}>
                          YTD
                        </Button>
                        <Button className={`mr-1 ${this.state.active === "1A" ? 'active' : ''}`} variant="light" id="1A" size="sm" onClick={this.handleClick(86400, 525600)}>
                          1A
                        </Button>
                        <Button className={`mr-1 ${this.state.active === "MAX" ? 'active' : ''}`} variant="light" id="MAX" size="sm" onClick={this.handleClick(259200, 7000000)}>
                          MAX
                        </Button>
                      </div>
                    </Col></Row>
                  <ComboChart symbol={this.props.match.params.Symbol.toUpperCase()} frequency={this.state.frequency} interval={this.state.interval} last={coins[0].Last} />
                </Tab.Pane>
              </Tab.Content>
            </Container>
          </Tab.Container>
          <Container className="pt-4 ">
            <NewsFeed slug={exchanges[0].SymbolName} />
          </Container>
        </>
      );
    }
  }
}

export default SymbolMarket;